import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { ChangeEvent, FocusEvent, useContext, useEffect, useState } from "react";
import { StepContext } from "../../context";
import { CheckoutChargeAddress, CheckoutCreditCard, CheckoutInterface, LinkInterface, PriceInterface } from '../../@interfaces'
import { InputComponent } from "../../form/input";
import { InputMaskComponent } from "../../form/inputMask";
import { StepIndicator } from "../../stepIndicator";
import './styles.css'
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import logoSSL from '../../../assets/LOGO-CERTIFICADO.png'
import WarningIcon from '../../../assets/warning.png'

import CVVImage from '../../../assets/CVV.png'
import congratulationsImage from '../../../assets/congratulations.png'

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { cardValidator } from "./validator";
import { formatinputs } from "../../../utils/formatCpf";

import Axios, { CancelTokenSource } from 'axios'
import { Tarja } from "../../tarja";
import { transformValue } from "../../../utils/transformValue";
import { useSnackbar } from "notistack";
import { OperationError } from "../../operationError";
import { formatCurrency } from "../../../utils/formatCurrency";
import arrow from '../../../assets/arrow.svg'
import { awaitTime, isOfficeHour } from "../../../utils/timing";
import { io } from "socket.io-client";

export function PagWay() {
  const { enqueueSnackbar } = useSnackbar()
  const { setStep, step, progressIndicator, setProgressIndicator,
    setOperationSuccess, proposalToken, checkoutProposal, setCheckoutProposal,
    setLoading, setGetStatus, cardLimit, setCardLimit, linkId, setDataLink,
    dataLink, setSaleTable, preApproved, setPreApproved,
    valueProposal, setValueProposal, saleTable, setDataPrice, dataPrice,
    proposalNumber, name: clientName, checkoutSteps, setCheckoutSteps,
    setRegisterSteps, proposalAmounts, setProposalAmounts, ownerTyping, CPF, setAssertiva, getStatus, challengeId, setChallengeId, HandleSteps, backProposal } = useContext(StepContext)
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [openDialogCVV, setOpenDialogCVV] = useState<boolean>(false)
  const [cvc, setCvc] = useState<string | number>('')
  const [expiry, setExpiry] = useState<string>('')
  const [number, setNumber] = useState<string>('')
  const [focus, setfocus] = useState<any>('')
  const [errorList, setErrorList] = useState<{ message: string; path: string }[]>([])
  const [openFormError, setOpenFormError] = useState<boolean>(false)
  const [openDialogCardError, setOpenDialogCardError] = useState<boolean>(false)
  const [name, setName] = useState<string>('')
  const [errorReason, setErrorReason] = useState('')
  const [openDialogForLimit, setOpenDialogForLimit] = useState(false)
  const [cardLimitIsHigher, setCardLimitIsHigher] = useState<boolean>(false)
  const [openDialogError, setOpenDialogError] = useState<boolean>(false)
  const minValue = 300
  const [maxValue, setmaxValue] = useState(5000)
  const [otherValue, setOtherValue] = useState<boolean>(false)
  const [messageError, setMessageError] = useState<string>('')
  const [openDefaultAlert, setOpenDefaultAlert] = useState<boolean>(false)
  const [redonlyAlert, setOpenRedonlyAlert] = useState<boolean>(false)
  const [selected, setselected] = useState<PriceInterface | null>(null)
  const [valueFor18, setValueFor18] = useState<number>(0)
  const [valueFor24, setValueFor24] = useState<number>(0)
  const progressIndicatorStyle = { "--progress": progressIndicator * 10 } as React.CSSProperties

  const [blackListValues, setBlackListValues] = useState<Array<number>>([])
  const [action, setAction] = useState('')
  const [valueInBlackList, setValueInBlackList] = useState<boolean>(false)
  const [openDialogCardAproved, setOpenDialogCardAproved] = useState(false)
  const [openDialogForCardBlock, setOpenDialogForCardBlock] = useState(false)
  const [openDialogAlert, setOpenDialogAlert] = useState(false)
  const [openFirtInsufficientLimit, setOpenFirtInsufficientLimit] = useState(false)
  const [openSummary, setOpenSummary] = useState<boolean>(false)
  const [openDialogTimer, setOpenDialogTimer] = useState(false)
  const [timerCount, setTimerCount] = useState<number>(0)
  const [showPreApproved, setShowPreApproved] = useState(false)
  const [showParcels, setShowParcels] = useState(false)
  const [openDialogSameValue, setOpenDialogSameValue] = useState(false)

  const [openDialogNotAproved, setOpenDialogNotAproved] = useState(false)
  const [openDialogNotAprovedByChallenge, setOpenDialogNotAprovedByChallenge] = useState(false)

  const [openDialogChallenge, setOpenDialogChallenge] = useState(false)
  const [openDialogAvoidBlocking, setOpenDialogAvoidBlocking] = useState(false)
  const [openDialogOfficeHour, setOpenDialogOfficeHour] = useState(false)

  const [openDialogFailed3ds, setOpenDialogFailed3ds] = useState(false)

  const [canRetry, setCanRetry] = useState(false) // false


  const threeDSCallBack = (jwt: string, status: string) => {
    console.log('-----------------------', jwt, status)
    if (status === "Approved") {
      sendJWT3DS(jwt)
    } else if (status === "Cancelled") {
      finishProposal({
        failed3ds: true
      })
      setOpenDialogFailed3ds(true)
      console.log("CHALLENGE CANCELADO")
    }
  }

  const device = {
    httpAcceptBrowserValue:
      "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,/;q=0.8",
    httpAcceptContent:
      "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,/;q=0.8",
    httpBrowserLanguage: navigator.language,
    httpBrowserJavaEnabled: navigator.javaEnabled() == true ? "Y" : "N",
    httpBrowserJavaScriptEnabled: "Y",
    httpBrowserColorDepth: window.screen.colorDepth,
    httpBrowserScreenHeight: window.innerHeight,
    httpBrowserScreenWidth: window.innerWidth,
    httpBrowserTimeDifference: new Date().getTimezoneOffset(),
    userAgentBrowserValue: navigator.userAgent,
  };

  const errorsCasesCheckoutPagway: Array<{
    code: string,
    message: string,
    action: string
  }> = [
      {
        code: '01',
        message: 'Os dados do cartão são inválidos. Por favor digite corretamente.',
        action: 'continue'
      },
      {
        code: '02',
        message: 'Não foi possível realizar esta transação. Contate a central 0800 do seu cartão e solicite o desbloqueio para compras pela internet.',
        action: 'continue'
      },
      {
        code: '03',
        message: 'Entre em contato com o 0800 do banco ou emissor do seu cartão, solicite a liberação para compras pela internet, e tente novamente assim que liberado.',
        action: 'continue'
      },
      {
        code: '04',
        message: 'Os dados do cartão são inválidos ou ainda está bloqueado. Contate a central 0800 do seu cartão e solicite o desbloqueio para compras pela internet.',
        action: 'continue'
      },
      {
        code: '05',
        message: 'Os dados do cartão são inválidos. Por favor digite corretamente.',
        action: 'continue'
      },
      {
        code: '06',
        message: 'Função crédito não está permitida para esse cartão. Por favor informe os dados de um cartão de crédito.',
        action: 'continue'
      },
      {
        code: '08',
        message: 'Houve uma instabilidade no processamento e a transação não foi realizada. Por favor digite os dados novamente.',
        action: 'continue'
      },
      {
        code: '09',
        message: 'Os dados do cartão são inválidos ou ainda está bloqueado. Contate a central 0800 do seu cartão e solicite o desbloqueio para compras pela internet.',
        action: 'continue'
      },
      {
        code: '010',
        message: 'O SALDO DISPONÍVEL em seu cartão de crédito não é suficiente para realizar essa transação. Clique no botão abaixo SIMULAR OUTRO VALOR.',
        action: 'simulator'
      },
      {
        code: '011',
        message: 'Não foi possível realizar esta transação. Contate a central 0800 do seu cartão e solicite o desbloqueio para compras pela internet.',
        action: 'simulator'
      },
      {
        code: '012',
        message: 'Transação Negada pelo emissor do seu cartão de crédito.',
        action: 'finish'
      },
      {
        code: '012',
        message: 'Transação recusada pelo emissor por haver valores em duplicidade. Clique no botão SIMULAR OUTRO VALOR.',
        action: 'simulator'
      },
      {
        code: '013',
        message: 'Houve uma instabilidade no processamento e a transação não foi realizada. Por favor digite os dados novamente.',
        action: 'continue'
      },
      {
        code: '014',
        message: 'Os dados do cartão são inválidos. Por favor digite corretamente.',
        action: 'continue'
      },
      {
        code: '015',
        message: 'Não foi possível realizar esta transação. Contate a central 0800 do seu cartão e solicite o desbloqueio para compras pela internet.',
        action: 'continue'
      },
      {
        code: '016',
        message: 'Houve uma instabilidade no processamento e a transação não foi realizada. Por favor digite os dados novamente.',
        action: 'continue'
      },
      {
        code: '017',
        message: 'O SALDO DISPONÍVEL em seu cartão de crédito não é suficiente para realizar essa transação. Clique no botão abaixo SIMULAR OUTRO VALOR.',
        action: 'simulator'
      },
      {
        code: '018',
        message: 'Os dados do cartão são inválidos. Por favor digite corretamente.',
        action: 'continue'
      },
      {
        code: '019',
        message: 'Não foi possível realizar esta transação. Contate a central 0800 do seu cartão e solicite o desbloqueio para compras pela internet.',
        action: 'continue'
      },
      {
        code: '020',
        message: 'Os dados do cartão são inválidos. Por favor digite corretamente.',
        action: 'continue'
      },
      {
        code: '021',
        message: 'Transação Negada pelo emissor do seu cartão de crédito.',
        action: 'finish'
      },
      {
        code: '022',
        message: 'Não foi possível realizar esta transação. Contate a central 0800 do seu cartão e solicite o desbloqueio para compras pela internet.',
        action: 'continue'
      },
      {
        code: '023',
        message: 'Os dados do cartão são inválidos ou ainda está bloqueado. Contate a central 0800 do seu cartão e solicite o desbloqueio para compras pela internet.',
        action: 'continue'
      },
      {
        code: '024',
        message: 'Não foi possível realizar esta transação. Contate a central 0800 do seu cartão e solicite o desbloqueio para compras pela internet.',
        action: 'continue'
      },
      {
        code: '025',
        message: 'Transação Negada pelo emissor do seu cartão de crédito.',
        action: 'finish'
      },
      {
        code: '026',
        message: 'Houve uma instabilidade no processamento e a transação não foi realizada. Por favor digite os dados novamente.',
        action: 'continue'
      }
    ]

  const codesToSendBlockedCard = {
    acanto: [
      "890",
      "881",
      "901",
      "902",
      "903",
      "868",
      "861",
      "869"
    ],
    pagway: [
      "015",
      "012",
      "019",
      "022",
      "023",
      "024"
    ]
  }

  const errorsCasesCheckoutAcanto: Array<{
    code: string,
    message: string,
    action: string
  }> = [
      { code: "700", message: "Erro durante o processo. Análise não realizada", action: "continue" },
      { code: "701", message: "Cartão já possui operação aprovada na data de hoje", action: "continue" },
      { code: "702", message: "Cartão já possui operação aprovada nos últimos 15 dias", action: "continue" },
      { code: "703", message: "Compra com valor superior a 30% da maior já efetuada com o cartão | Entre em contato com o banco emissor do cartão através do 0800 e solicite autorização para transações e-commerce", action: "continue" },
      { code: "704", message: "CNPJ/CPF já possui operação aprovada na data de hoje", action: "continue" },
      { code: "705", message: "CNPJ/CPF já possui operação aprovada nos últimos 15 dias", action: "continue" },
      { code: "706", message: "CNPJ/CPF com valor superior a 30% da maior já efetuada com o cartão | Entre em contato com o banco emissor do cartão através do 0800 e solicite autorização para transações e-commerce", action: "continue" },
      { code: "707", message: "Valor superior a 30% da maior venda já efetuada pelo varejista | Entre em contato com o banco emissor do cartão através do 0800 e solicite autorização para transações e-commerce", action: "continue" },
      { code: "708", message: "Link de pagamento está na stop list", action: "continue" },
      { code: "709", message: "Seller está na stop list", action: "continue" },
      { code: "802", message: "Valor a ser debitado no cartão de crédito não foi informado", action: "continue" },
      { code: "803", message: "Código de segurança do cartão inválido", action: "continue" },
      { code: "804", message: "Data de expiração do cartão inválida", action: "continue" },
      { code: "805", message: "Número do cartão de crédito inválido", action: "continue" },
      { code: "806", message: "Erro durante o processo de validação de dados", action: "continue" },
      { code: "807", message: "Erro durante a criação do objeto BUYER", action: "continue" },
      { code: "809", message: "Erro durante o processo de informação dos valores de pagamento do cartão de crédito", action: "continue" },
      { code: "810", message: "Erro durante o processo de execução da api de pagamento", action: "continue" },
      { code: "811", message: "Erro durante o processo de obtençao dos cartões de crédito do cliente", action: "continue" },
      { code: "812", message: "Erro durante o processo de obtençao do status da operação", action: "continue" },
      { code: "813", message: "Erro durante o processo de consulta da operação", action: "continue" },
      { code: "814", message: "Erro durante o processo de estorno de um pagamento por cartão de crédito", action: "continue" },
      { code: "815", message: "Cadastro do varejista no operador, não foi localizado", action: "continue" },
      { code: "816", message: "Erro não tratato durante processamento de pagamento por cartão de crédito", action: "continue" },
      { code: "817", message: "Email do pagador não informado", action: "continue" },
      { code: "818", message: "CPF do pagador não informado", action: "continue" },
      { code: "820", message: "Nome do pagador não informado", action: "continue" },
      { code: "823", message: "Identificador do cartão a ser utilizado não informado", action: "continue" },
      { code: "824", message: "Identificador do varejista não informado", action: "continue" },
      { code: "826", message: "Operação somente permitida para pagamentos realizados com a cartão de crédito", action: "continue" },
      { code: "827", message: "Operação já foi estornada anteriormente", action: "continue" },
      { code: "828", message: "Operação de estorno não permitida para o status atual da transação", action: "continue" },
      { code: "831", message: "Email do comprador não informado", action: "continue" },
      { code: "832", message: "CPF do comprador não informado", action: "continue" },
      { code: "834", message: "Nome do comprador não informado", action: "continue" },
      { code: "835", message: "Cidade do comprador não informado", action: "continue" },
      { code: "844", message: "Nenhuma resposta inteligível foi recebida do operador. Transação não processada", action: "continue" },
      { code: "845", message: "Erro retornado quando o operador encontrou inconsistência nos dados, mas não informa do que se trata", action: "continue" },
      { code: "847", message: "Autorizador da operação está temporariamente fora do ar. Transação não processada", action: "continue" },
      { code: "852", message: "Valor da operação não pode ser inferior a 5,00", action: "continue" },
      { code: "860", message: "Transação não foi capturada pelo autorizador", action: "continue" },
      { code: "861", message: "Operação não pode ser executada", action: "continue" },
      { code: "862", message: "Vendedor não está autorizado a operar com cartão de crédito", action: "continue" },
      { code: "863", message: "Transação não pode ser desfeita", action: "continue" },
      { code: "864", message: "Mês de expiração inválido", action: "continue" },
      { code: "865", message: "Ano de expiração inválido", action: "continue" },
      { code: "867", message: "Cartão não possui saldo para a operação", action: "simulator" },
      { code: "868", message: "Cartão expirado", action: "continue" },
      { code: "869", message: "Número de cartão de crédito inválido", action: "continue" },
      { code: "877", message: "A operadora caiu ou está sendo atualizada", action: "continue" },
      { code: "878", message: "Transação não processada", action: "continue" },
      { code: "879", message: "Transação não autorizada pelo anti-fraude", action: "finish" },
      { code: "880", message: "Transação selecionada para revisão pela mesa do anti-fraude. Prazo máximo é 48 horas", action: "continue" },
      { code: "881", message: "Transação não foi analisada pelo anti-fraude, apesar da solicitação. Deve ser abortada.", action: "finish" },
      { code: "889", message: "Erro interno na API do prestador de serviço de anti-fraude", action: "continue" },
      { code: "890", message: "Cartão declinado", action: "continue" },
      { code: "900", message: "Tempo da última operação realizada com o cartão é inferior a 2 minutos", action: "continue" },
      { code: "901", message: "Operação não autorizada. Tente novamente | Entre em contato com o banco emissor do cartão através do 0800 e solicite autorização para transações e-commerce", action: "continue" },
      { code: "902", message: "Operação não autorizada. Contacte o emissor do cartão | Entre em contato com o banco emissor do cartão através do 0800 e solicite autorização para transações e-commerce", action: "continue" },
      { code: "903", message: "Operação não autorizada. Cartão Bloqueado | Entre em contato com o banco emissor do cartão através do 0800 e solicite autorização para transações e-commerce", action: "continue" },
      { code: "REA_1002", message: "Email já utilizado em outro CPF", action: "continue" },
    ]

  useEffect(() => {
    (async () => {
      const open = isOfficeHour()
      if (!open) {
        setOpenDialogOfficeHour(true)
      }
      setPreApproved(0)
      setCardLimit('0')
      const timerLocalStorage = sessionStorage.getItem('tc-timer')
      setTimerCount(Number(timerLocalStorage) > 0 ? Number(timerLocalStorage) : 0)
      setCheckoutSteps(4)
      if (ownerTyping) {
        setmaxValue(5000)
      }
      // if (backProposal.checkout_status === "challenge_3ds") {
      //   //@ts-ignore
      //   const threeDS = new Adiq3ds("ccnum", threeDSCallBack);

      //   threeDS.InitChallenge(backProposal.preValidationCheckout?.desafio3ds?.threeDs?.acsUrl, backProposal.preValidationCheckout?.desafio3ds?.threeDs?.pareq, backProposal.preValidationCheckout?.desafio3ds?.threeDs?.authenticationTransactionId);
      // }
    })()
  }, [])

  useEffect(() => {
    (async () => {
      await awaitTime(0.1)
      if (timerCount > 0) {
        if (!openDialogTimer) {
          setOpenDialogTimer(true)
        }
        setTimeout(() => {
          setTimerCount(timerCount - 1)
        }, 900);
      } else {
        setOpenDialogTimer(false)
      }
      sessionStorage.setItem('tc-timer', timerCount.toString())
    })()

  }, [timerCount])


  useEffect(() => {
    setCardLimitIsHigher(Number(cardLimit.replace('.', '').replace(',', '').replace(/\D/g, '')) > 0)
  }, [cardLimit])

  useEffect(() => {
    (async () => {
      setValueInBlackList(!blackListValues.includes(transformValue(valueProposal)))

      if (transformValue(valueProposal) !== 0) {
        const filter = dataPrice.find((i) => i.parcels === 12) as PriceInterface

        if (filter) {
          const value18x = filter.total + 3
          setValueFor18(value18x)

          const value24x = value18x + 3
          setValueFor24(value24x)
        }
      }
    })()
  }, [valueProposal])

  useEffect(() => {
    if (selected) {
      amountUpdate()
    }
  }, [selected])

  useEffect(() => {

    (async () => {
      if (getStatus === true) {
        await awaitTime(5)
        setGetStatus(false)
        getCheckoutStatus()
      }
    })()

  }, [getStatus])

  const getCheckoutStatus = async () => {
    await Axios.get(`${process.env.REACT_APP_ONLINE_API}/proposals`, {
      headers: {
        'Content-Type': 'application/json',
        'x-token': proposalToken,
      }
    }).then(({ data }) => {
      if (data.proposal.proposal_status === "transfeera") {
        setOpenDialogCardAproved(true)
        setGetStatus(false)
        HandleSteps(3)
      } else if (data.proposal.proposal_status === "failed") {
        setOpenDialogNotAprovedByChallenge(true)
        setGetStatus(false)
      } else {
        setGetStatus(true)
      }
    })
  }

  useEffect(() => {

    if (checkoutSteps === 6) {
      if (proposalNumber) {
        const socket = io(process.env.REACT_APP_ONLINE_WS as string);
        socket.on(proposalNumber.toString(), (...args) => {
          console.log(args)
          if (args[0] === "update_challenge") {
            getCheckoutStatus()
          }
        });
      }
    }
  }, [checkoutSteps])



  const sendToShow = async (point: string) => {
    await awaitTime(0.3)
    switch (point) {
      case "parcels":
        const parcelElement = document.getElementById('parcels')
        /*@ts-ignore*/
        if (parcelElement) {
          parcelElement.scrollIntoView({
            block: "start",
            behavior: "smooth"
          });
        }
        break;
      case "preApproved":
        const preApprovedElement = document.getElementById('preApproved')
        /*@ts-ignore*/
        if (preApprovedElement) {
          preApprovedElement.scrollIntoView({
            block: "start",
            behavior: "smooth"
          });
        }
        break;

      case "limit":
        const limitElement = document.getElementById('limit')
        /*@ts-ignore*/
        if (limitElement) {
          limitElement.scrollIntoView({
            block: "start",
            behavior: "smooth"
          });
        }
        break;

      default:
        const element = document.getElementById('otherValue')
        /*@ts-ignore*/
        if (element) {
          element.scrollIntoView({
            block: "center",
            behavior: "smooth"
          });
        }
    }
  }

  const CheckoutIndicator = () => {
    return (
      <div>
        <div className="progressIndicator">
          <button
            style={{ opacity: checkoutSteps === 1 || (checkoutSteps === 4 && ownerTyping) ? 0.3 : 1 }}
            onClick={() => {
              if (checkoutSteps === 1 || (checkoutSteps === 4 && ownerTyping)) {
                return
              } else {
                setCheckoutSteps(1)
                sendToShow("parcels")
                setProgressIndicator(progressIndicator - 1)
              }
            }}
          >
            <img src={arrow} alt="arrow" />
          </button>
          <p>{progressIndicator <= 3 ? 'Primeiros passos' : ''}</p>
          <p className='indicatorNumber'>{progressIndicator}/6</p>
        </div>
        <div className='indicationBar' style={progressIndicatorStyle} />
      </div>
    )
  }

  const moneyMask = (value: string) => {
    value = value.replace('.', '').replace(',', '').replace(/\D/g, '')

    const options = { minimumFractionDigits: 2 }
    const result = new Intl.NumberFormat('pt-BR', options).format(
      parseFloat(value) / 100
    )

    return 'R$ ' + result
  }

  const handleFocus = (e: ChangeEvent<any>) => {
    if (focus !== e.target.name) {
      setfocus(e.target.name)
    }
    else {
      return
    }
  }

  const handleExpiry = (e: ChangeEvent<any>) => {
    handleFocus(e)
    const value = e.target.value
    const month = value.split('/')[0].split('')
    const year = value.split('/')[1].split('')

    const input = document.getElementById('expiry')

    if (month[0] !== '_') {
      if (Number(month[0]) > 1) {
        setExpiry(`0${month[0]}/__`)
        window.setTimeout(function () {
          /*@ts-ignore*/
          input.setSelectionRange(3, 3);
        }, 0);
      }
      if (Number(month[0]) === 1) {
        if (Number(month[1] > 2)) {
          setExpiry(`12/__`)
          window.setTimeout(function () {
            /*@ts-ignore*/
            input.setSelectionRange(3, 3);
          }, 0);
        } else {
          setExpiry(value)
        }
      }
      if (Number(month[0]) === 0) {
        if (Number(month[1]) === 0) {
          setExpiry(`01/__`)
          window.setTimeout(function () {
            /*@ts-ignore*/
            input.setSelectionRange(3, 3);
          }, 0);
          return
        } else {
          setExpiry(value)
        }
      }
    } else {
      setExpiry(value)
    }
    if (year[0] !== '_') {
      const yearInit = new Date().getUTCFullYear().toString().split('0')[1].split('')[0]
      const yearEnd = new Date().getUTCFullYear().toString().split('0')[1].split('')[1]
      if (year[0] < yearInit) {
        setExpiry(`${month[0]}${month[1]}/${yearInit}`)
      }
      if (Number(year[0]) <= Number(yearInit) && year[1] !== '_') {
        if (Number(year[1]) <= Number(yearEnd)) {
          if (Number(expiry.split('/')[0]) < new Date().getMonth() + 1) {
            setExpiry(`${month[0]}${month[1]}/${year[0]}${Number(yearEnd) + 1}`)
          } else {
            setExpiry(`${month[0]}${month[1]}/${year[0]}${yearEnd}`)
          }
        }
      }
    }
  }

  const sendCheckoutRequest = async () => {

    var checkout = checkoutProposal
    const cardInfo: CheckoutCreditCard = {}
    cardInfo.cardHolder = name
    cardInfo.cpf = checkout?.checkout.clientCpf
    cardInfo.cvv = cvc as string
    cardInfo.dueDate = expiry
    cardInfo.number = formatinputs.toString(number)
    //@ts-ignore
    const threeDS = new Adiq3ds("ccnum", threeDSCallBack);

    await threeDS.Authorization3ds().then(() => {
      const threeDsCode = threeDS.getThreeDsCode();

      cardInfo.threeDsCode = threeDsCode
      cardInfo.dispositivo = device
      // inicia autorizacao-captura
    });

    console.log(cardInfo)

    if (name.split(' ')[0].toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") !== clientName.split(' ')[0].toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")) {
      setOpenDialogNotAproved(true)
      return
    }

    const data: CheckoutInterface = {
      checkout: {
        chargeAddress: checkout?.checkout.chargeAddress as CheckoutChargeAddress,
        clientCpf: checkout?.checkout.clientCpf as string,
        creditCard: cardInfo
      }
    }
    // setOpenDialog(false)
    setLoading(true)
    await Axios.patch(`${process.env.REACT_APP_ONLINE_API}/proposals`, data, {
      headers: {
        'Content-Type': 'application/json',
        'x-token': proposalToken,
      }
    }).then(({ data }) => {
      console.log(data)
      if (data.checkout_status === 'challenge') {
        setChallengeId(data.integrations.acantoTransaction.id)
        setOpenDialogChallenge(true)
      } else if (data.checkout_status === 'challenge_3ds') {
        threeDS.InitChallenge(data.challenge.acsUrl, data.challenge.pareq, data.challenge.authenticationTransactionId);
      } else if (data.checkout_status === "failed") {
        setOpenDialogCardError(true)
        setErrorReason(data.checkout_desc)
      } else {
        HandleSteps(3)
        setOpenDialogCardAproved(true)
      }
    }).catch((e) => {
      try {
        if (e.response.data.point === "blockedCard") {
          setOpenDialogForCardBlock(true)
          return
        }

        const lastTry = e.response.data.message.lastTry as "pagway" | "acanto"

        let integrations = e.response.data.message.integration


        const casesErrors = {
          "pagway": errorsCasesCheckoutPagway,
          "acanto": errorsCasesCheckoutAcanto
        }

        const code = casesErrors[lastTry].filter((item) => item.code === (lastTry === "pagway" ? integrations.preValidationCheckout?.codigo : integrations.acantoTransaction[0].reason_code))

        if (code.length > 0) {
          if (code.length === 1) {
            if (code[0].code === '010' || code[0].code === '017' || code[0].code === '867') {
              setOpenFirtInsufficientLimit(true)
              setLoading(false)
              return
            }
            if (codesToSendBlockedCard[lastTry].includes(code[0].code)) {
              setErrorReason('Seu cartão de crédito ainda está bloqueado para compras online 🔒. Entre em contato com o atendimento 📞 0800 no verso do seu cartão e solicite o DESBLOQUEIO 🔓')
              // setCanRetry(true)
            } else {
              setErrorReason(code[0].message)
              setAction(code[0].action)
            }
            setOpenDialogForLimit(true)
          } else {
            if (e.response.data.message.message.includes('duplicidade')) {
              const correct = code.find((item) => item.message.includes('duplicidade'))
              setErrorReason(correct?.message as string)
              setAction(correct?.action as string)
              setOpenDialogForLimit(true)
              // const value = blackListValues
              // value.push(transformValue(valueProposal))
              // setBlackListValues(value)
            } else {
              const correct = code.find((item) => item.action === "finish")
              setErrorReason(correct?.message as string)
              setAction(correct?.action as string)
              setOpenDialogForLimit(true)
            }
          }
        } else {
          setOpenDialogCardError(true)
          setErrorReason(lastTry === "pagway" ? integrations.preValidationCheckout.message : integrations.acantoTransaction.reason_message)
        }
      } catch (error) {
        enqueueSnackbar('Falha ao realizar transação, por favor entre em contato com o suporte para mais informações.', {
          variant: 'error'
        })
      }

    }).finally(() => {
      setLoading(false)
    })
  }

  const amountUpdate = async () => {
    setLoading(true)
    const CancelToken = Axios.CancelToken;
    const cancelTokenSource = CancelToken.source();
    cancelRequest(cancelTokenSource)
    const data = {
      parcels: selected?.parcels as number,
      net_amount: transformValue(valueProposal),
      link: linkId,
      credit_card_limit: transformValue(cardLimit)
    }

    await Axios.patch(`${process.env.REACT_APP_ONLINE_API}/proposals/amount`, data, {
      headers: {
        'x-token': proposalToken
      },
      cancelToken: cancelTokenSource.token
    }).then(({ data }) => {
      setCheckoutSteps(4)
      setProposalAmounts({
        parcels: selected?.parcels as number,
        total: selected?.total as number,
        value_parcels: selected?.parcelAmount as number
      })
      setProgressIndicator(progressIndicator + 1)
    }).catch((e) => {
      if (Axios.isCancel(e)) {
        return amountUpdate()
      }
    }).finally(() => {
      setLoading(false)
    })
  }

  async function cancelRequest(cancelToken: CancelTokenSource) {
    await awaitTime(3)
    cancelToken.cancel('')
  }


  const nextStep = () => {
    cardValidator.validate({
      name,
      number: formatinputs.toString(number),
      expiry: formatinputs.toString(expiry),
      cvc
    }, {
      abortEarly: false
    }).then(() => {
      sendCheckoutRequest()
      // setOpenDialog(true)
    }).catch(async (err) => {
      const array: { message: string; path: string }[] = []
      await err.inner.forEach((element: any) => {

        if (array.filter(e => e.path === element.path).length === 0) {
          array.push({ message: element.message, path: element.path })
        }
      })
      setErrorList(array)
      setOpenFormError(true)
    })

  }

  async function getDataLink() {
    setLoading(true)
    await Axios.get(`${process.env.REACT_APP_ONLINE_API}/links/${linkId}`).then(async ({ data }) => {
      let maxValueLocal = 5000
      setDataLink(data.result)
      setSaleTable(data.result[0].sale_table)

      const lowestRate: LinkInterface = data?.result?.reduce((prev: LinkInterface, current: LinkInterface) => {
        return prev.parcels < current.parcels ? prev : current
      })

      const higherRate: LinkInterface = data?.result?.reduce((prev: LinkInterface, current: LinkInterface) => {
        return prev.parcels > current.parcels ? prev : current
      })

      const valuePreApproved = (transformValue(cardLimit) * 0.97) / (1 + (higherRate.fees_tax))
      if (valuePreApproved < minValue) {
        setPreApproved(minValue * (higherRate.fees_tax + higherRate.prime_tax) + minValue)
        setCheckoutSteps(5)
      } else {
        if (valuePreApproved < maxValueLocal) {
          const redonlyValue = valuePreApproved % 10
          if (redonlyValue === 0) {
            if (blackListValues.includes(valuePreApproved)) {
              setPreApproved(valuePreApproved !== 300 ? valuePreApproved - 10 : valuePreApproved)
            } else {
              setPreApproved(valuePreApproved)
            }
          } else {
            if (blackListValues.includes(valuePreApproved - redonlyValue)) {
              setPreApproved(valuePreApproved - redonlyValue !== 300 ? (valuePreApproved - redonlyValue) - 10 : valuePreApproved - redonlyValue)
            } else {
              setPreApproved(valuePreApproved - redonlyValue)
            }
          }
        } else {
          setPreApproved(maxValueLocal)
        }
        // setCheckoutSteps(2)
        // setProgressIndicator(progressIndicator + 1)
        setShowPreApproved(true)
        setShowParcels(false)
        sendToShow('preApproved')
      }

    }).catch(() => {
      enqueueSnackbar('Falha ao buscar dados do link', {
        variant: 'error'
      })
    }).finally(() => {
      setLoading(false)
    })

  }

  const getPrices = async (value: number | string) => {
    setLoading(true)
    const verifyValue = await Axios.get(`${process.env.REACT_APP_ONLINE_API}/proposals/consult-cpf/${formatinputs.toString(CPF)}?verifyValue=true&amount=${typeof value === 'string' ? transformValue(value) : value}`).then(({ data }) => data)
    console.log(verifyValue)
    if (verifyValue.result.continue === false && verifyValue.result.amount) {
      setOpenDialogSameValue(true)
      setLoading(false)
      return
    }
    const dataPayload = {
      amount: typeof value === 'string' ? transformValue(value) : value,
      sale_table: saleTable
    }
    const CancelToken = Axios.CancelToken;
    const cancelTokenSource = CancelToken.source();
    cancelRequest(cancelTokenSource)
    await Axios.post(`${process.env.REACT_APP_ONLINE_API}/proposals/price`, dataPayload, {
      cancelToken: cancelTokenSource.token
    }).then(({ data }) => {
      setDataPrice(data)
      setValueProposal(formatCurrency(dataPayload.amount))
      setShowParcels(true)
      sendToShow("parcels")
      // setCheckoutSteps(checkoutSteps + 1)
      // setProgressIndicator(progressIndicator + 1)
    }).catch((e) => {
      if (Axios.isCancel(e)) {
        return getPrices(value)
      }
    }).finally(() => setLoading(false))
  }

  const render = () => {
    switch (checkoutSteps) {
      case 1:
        return (
          <div className="divCardLimit defaultClassForTarja">
            <CheckoutIndicator />
            <p id="limit">Digite abaixo apenas o <br /> <strong style={{ color: 'red' }}>SALDO <u style={{ color: 'red' }}>DISPONÍVEL</u> <br /> PARA COMPRAS </strong><br /> em seu cartão de crédito
            </p>
            {/* <div className="textCard">
              <p>Digite abaixo o SALDO DISPONÍVEL agora em seu cartão de crédito:</p>
            </div> */}

            <div className="valueCardLimit">
              <input
                inputMode="tel"
                type="text"
                value={moneyMask(cardLimit)}
                onChange={(e) => {
                  if (transformValue(e.target.value) < 1000000) {
                    setCardLimit(e.target.value)
                  } else {
                    setCardLimit(moneyMask("99999999"))
                  }
                  if (showPreApproved) {
                    setShowPreApproved(false)
                  }
                }}
                style={{
                  color: cardLimitIsHigher ? "#000" : "var(--border-color)",
                  backgroundColor: cardLimitIsHigher ? "rgba(190, 245, 74, 0.16)" : "rgba(242, 242, 242,0.5)",
                  borderColor: cardLimitIsHigher ? "#BBE54B" : "var(--border-color)"
                }} />

              <div />
              <button
                className={cardLimitIsHigher && !showPreApproved ? 'buttonCardActive' : 'buttonCardDisabled'}
                onClick={() => {
                  if (cardLimitIsHigher) {
                    getDataLink()
                  } else {
                    setOpenDialogError(true)
                  }
                }} >Continuar</button>
              <img className="logoSSLVerify toTop noTop" src={logoSSL} alt="Logo SSL" />
            </div>

            {/* pre aproved */}

            {showPreApproved &&
              <>
                <div className="divCongratulations" id="preApproved">
                  <img src={congratulationsImage} alt="congratulationsImage" />
                  <p><strong>Receba</strong></p>
                </div>
                <div className="cardPreApproved">
                  {preApproved ? <p>{formatCurrency(preApproved as number)}</p> : <CircularProgress />}
                </div>
                <p className="informTimePayment">Em até 01 hora útil</p>

                <p>Este é o valor<br /> pré-aprovado para <br /> seu empréstimo!</p>

                <div className="containerButtonsProposal">
                  <button
                    onClick={() => {
                      getPrices(preApproved as number)
                      setValueProposal(formatCurrency(preApproved as number))
                      // setCheckoutSteps(4)
                    }}
                  >Quero o valor <br /> pré-aprovado!</button>
                  <button
                    style={{ marginBottom: !otherValue ? '15rem' : '0px' }}
                    onClick={() => {
                      setOtherValue(true)
                      sendToShow("otherValue")
                    }}>Quero um valor menor</button>
                </div>
                {otherValue &&
                  <div className="otherValue" id="otherValue">
                    <p>Digite abaixo o valor desejado de até <strong>{formatCurrency(preApproved as number)}:</strong></p>
                    <p>Aviso: Digite apenas múltiplos de <strong>{formatCurrency(10)}</strong></p>

                    <div className="valueCardLimit">
                      <input
                        inputMode="tel"
                        type="text"
                        value={moneyMask(valueProposal)}
                        onChange={(e) => {
                          setValueProposal(e.target.value)
                        }}
                        style={{
                          color: cardLimitIsHigher ? "#000" : "var(--border-color)",
                          backgroundColor: cardLimitIsHigher ? "rgba(190, 245, 74, 0.16)" : "rgba(242, 242, 242,0.5)",
                          borderColor: cardLimitIsHigher ? "#BBE54B" : "var(--border-color)"
                        }} />
                      <div />
                      <button
                        style={{ marginBottom: '10px' }}
                        className={transformValue(valueProposal) > 0 && transformValue(valueProposal) <= Number(preApproved) && valueInBlackList ? 'buttonCardActive defaultMarginBottom' : 'buttonCardDisabled defaultMarginBottom'}
                        onClick={() => {
                          if (transformValue(valueProposal) <= 0 || transformValue(valueProposal) > Number(preApproved) || !valueInBlackList) {
                            setMessageError(transformValue(valueProposal) <= 0 ? 'Digite o valor desejado para continuar' : 'O valor do empréstimo precisa ser menor ou igual à')
                            setOpenDefaultAlert(true)
                          }
                          else {
                            if (transformValue(valueProposal) < minValue) {
                              setOpenDefaultAlert(true)
                              console.log('entrei aqui')
                              console.log(transformValue(valueProposal), minValue)
                            } else {
                              if (transformValue(valueProposal) % 10 !== 0) {
                                // setMessageError(`Digite apenas múltiplos de R$ 10. Ex.: ${formatCurrency(transformValue(valueProposal) - (transformValue(valueProposal) % 10))}`)
                                setOpenRedonlyAlert(true)
                              } else {
                                getPrices(valueProposal)
                              }
                            }
                          }
                        }} >Continuar</button>
                    </div>
                  </div>
                }
              </>}

            {/* parcels */}
            {showParcels &&
              <>
                <p id="parcels">Escolha em <strong><u>quantas parcelas</u></strong> você quer pagar seu empréstimo de <strong><u>{valueProposal}</u></strong></p>

                <div className="listParcels">
                  {dataPrice.sort((a, b) => a.parcels - b.parcels).map((item: PriceInterface) => {
                    const lowestRate: PriceInterface = dataPrice.reduce((prev: PriceInterface, current: PriceInterface) => {
                      return prev.parcels < current.parcels ? prev : current
                    })
                    return (
                      <div className={lowestRate.parcels === item.parcels && !selected ? "parcel parcelPulse" : "parcel"} key={item.parcels}
                        onClick={() => {
                          if (selected) {
                            if (selected.parcels === item.parcels) {
                              setselected(null)
                            } else {
                              const filtered = dataPrice.find((i) => i.parcels === item.parcels)
                              setselected(filtered as PriceInterface)
                            }
                          } else {
                            const filtered = dataPrice.find((i) => i.parcels === item.parcels)
                            setselected(filtered as PriceInterface)
                          }
                        }}
                        style={{ backgroundColor: selected?.parcels === item.parcels ? 'var(--green-button-color)' : '#F2f2f2', opacity: !selected ? 1 : selected?.parcels === item.parcels ? 1 : 0.5 }}
                      >
                        <input onChange={() => { }} type="checkbox" value={item.parcels} checked={selected && selected?.parcels === item.parcels ? true : false} />
                        <div className="divValues">
                          <p>{item.parcels < 10 && '0'}{item.parcels}x de <strong>{formatCurrency(item.total / item.parcels)}</strong></p>
                          <p>Total: {formatCurrency(item.total)}</p>
                        </div>
                        <div className="divCets">
                          <p>Taxa ao mês</p>
                          <p>{(((item.total - transformValue(valueProposal)) / transformValue(valueProposal) * 100) / item.parcels).toFixed(2)}%</p>
                          <p>Taxa total</p>
                          <p>{((item.total - transformValue(valueProposal)) / transformValue(valueProposal) * 100).toFixed(2)}%</p>
                        </div>
                      </div>
                    )
                  })}
                  {/* <div className="parcel parcelDisabled" key={'18x'}>
                    <input onChange={() => { }} type="checkbox" value='18' checked={false} />
                    <div>
                      <p>18x de <strong>{formatCurrency(valueFor18 as number / 18)}</strong></p>
                      <p>Total: {formatCurrency(valueFor18 as number)}</p>
                    </div>
                  </div>
                  <div className="parcel parcelDisabled" key={'24x'}>
                    <input onChange={() => { }} type="checkbox" value='24' checked={false} />
                    <div>
                      <p>24x de <strong>{formatCurrency(valueFor24 as number / 24)}</strong></p>
                      <p>Total: {formatCurrency(valueFor24 as number)}</p>
                    </div>
                  </div> */}
                  <div className="info defaultMarginBottom">
                    <p><strong>Autorização de compra:</strong> avise ao atendimento do seu cartão de crédito que
                      fará uma compra online no valor de
                      {/* @ts-ignore */}
                      <strong> {formatCurrency(dataPrice.find((i) => i.parcels === 12).total)}</strong></p>

                  </div>

                </div>
              </>}

            <Tarja />

          </div>
        )
      case 2:
        return (
          <div className="divCardLimit defaultClassForTarja">
            <CheckoutIndicator />
            <div className="divCongratulations">
              <p><strong>Parabéns!</strong></p>
              <p style={{ fontSize: '14px' }}>{clientName}</p>
              <img src={congratulationsImage} alt="congratulationsImage" />
            </div>
            <div className="cardPreApproved">
              {preApproved ? <p>{formatCurrency(preApproved as number)}</p> : <CircularProgress />}
            </div>

            <p>é o valor pré-aprovado para seu empréstimo!</p>

            <div className="containerButtonsProposal">
              <button
                onClick={() => {
                  getPrices(preApproved as number)
                  setValueProposal(formatCurrency(preApproved as number))
                  // setCheckoutSteps(4)
                }}
              >Quero o valor pré-aprovado!</button>
              <button onClick={() => { setOtherValue(true) }}>Quero um valor menor</button>
            </div>
            {otherValue &&
              <div className="otherValue">
                <p>Digite abaixo o valor desejado de até <strong>{formatCurrency(preApproved as number)}:</strong></p>
                <p>Aviso: Digite apenas múltiplos de {formatCurrency(10)}</p>

                <div className="valueCardLimit">
                  <input
                    inputMode="tel"
                    type="text"
                    value={moneyMask(valueProposal)}
                    onChange={(e) => {
                      setValueProposal(e.target.value)
                    }}
                    style={{
                      color: cardLimitIsHigher ? "#000" : "var(--border-color)",
                      backgroundColor: cardLimitIsHigher ? "rgba(190, 245, 74, 0.16)" : "rgba(242, 242, 242,0.5)",
                      borderColor: cardLimitIsHigher ? "#BBE54B" : "var(--border-color)"
                    }} />
                  <div />
                  <button
                    style={{ marginBottom: '10px' }}
                    className={transformValue(valueProposal) > 0 && transformValue(valueProposal) <= Number(preApproved) && valueInBlackList ? 'buttonCardActive defaultMarginBottom' : 'buttonCardDisabled defaultMarginBottom'}
                    onClick={() => {
                      if (transformValue(valueProposal) <= 0 || transformValue(valueProposal) > Number(preApproved) || !valueInBlackList) {
                        setMessageError(transformValue(valueProposal) <= 0 ? 'Digite o valor desejado para continuar' : 'O valor do empréstimo precisa ser menor ou igual à')
                        setOpenDefaultAlert(true)
                      }
                      else {
                        if (transformValue(valueProposal) < minValue) {
                          console.log('entrei aqui')
                          setOpenDefaultAlert(true)
                        } else {
                          if (transformValue(valueProposal) % 10 !== 0) {
                            // setMessageError(`Digite apenas múltiplos de R$ 10. Ex.: ${formatCurrency(transformValue(valueProposal) - (transformValue(valueProposal) % 10))}`)
                            setOpenRedonlyAlert(true)
                          } else {
                            getPrices(valueProposal)
                          }
                        }
                      }
                    }} >Continuar</button>
                </div>
              </div>
            }
            <Tarja />

          </div>
        )
      case 3:
        return (
          <div className="divParcel">
            <CheckoutIndicator />

            <p>Escolha em <strong><u>quantas parcelas</u></strong> você quer pagar seu empréstimo de <strong><u>{valueProposal}</u></strong></p>

            <div className="listParcels">
              {dataPrice.sort((a, b) => a.parcels - b.parcels).map((item: PriceInterface) => {
                const lowestRate: PriceInterface = dataPrice.reduce((prev: PriceInterface, current: PriceInterface) => {
                  return prev.parcels < current.parcels ? prev : current
                })
                return (
                  <div className={lowestRate.parcels === item.parcels && !selected ? "parcel parcelPulse" : "parcel"} key={item.parcels}
                    onClick={() => {
                      if (selected) {
                        if (selected.parcels === item.parcels) {
                          setselected(null)
                        } else {
                          const filtered = dataPrice.find((i) => i.parcels === item.parcels)
                          setselected(filtered as PriceInterface)
                        }
                      } else {
                        const filtered = dataPrice.find((i) => i.parcels === item.parcels)
                        setselected(filtered as PriceInterface)
                      }
                    }}
                    style={{ backgroundColor: selected?.parcels === item.parcels ? 'var(--green-button-color)' : '#F2f2f2', opacity: !selected ? 1 : selected?.parcels === item.parcels ? 1 : 0.5 }}
                  >
                    <input onChange={() => { }} type="checkbox" value={item.parcels} checked={selected && selected?.parcels === item.parcels ? true : false} />
                    <div className="divValues">
                      <p>{item.parcels < 10 && '0'}{item.parcels}x de <strong>{formatCurrency(item.total / item.parcels)}</strong></p>
                      <p>Total: {formatCurrency(item.total)}</p>
                    </div>
                    <div className="divCets">
                      <p>CET</p>
                      <p>{item.cet_am}% a.m</p>
                      <p>{item.cet_aa}% a.a</p>
                    </div>
                  </div>
                )
              })}
              <div className="parcel parcelDisabled" key={'18x'}>
                <input onChange={() => { }} type="checkbox" value='18' checked={false} />
                <div>
                  <p>18x de <strong>{formatCurrency(valueFor18 as number / 18)}</strong></p>
                  <p>Total: {formatCurrency(valueFor18 as number)}</p>
                </div>
              </div>
              <div className="parcel parcelDisabled" key={'24x'}>
                <input onChange={() => { }} type="checkbox" value='24' checked={false} />
                <div>
                  <p>24x de <strong>{formatCurrency(valueFor24 as number / 24)}</strong></p>
                  <p>Total: {formatCurrency(valueFor24 as number)}</p>
                </div>
              </div>
              <div className="info defaultMarginBottom">
                <p><strong>Autorização de compra:</strong> avise ao atendimento do seu cartão de crédito que
                  fará uma compra online no valor de
                  {/* @ts-ignore */}
                  <strong> {formatCurrency(dataPrice.find((i) => i.parcels === 12).total)}</strong></p>

              </div>
              {/* <button
                style={{ marginBottom: '10px' }}
                className={selected ? 'buttonCardActive' : 'buttonCardDisabled'}
                onClick={() => {
                  if (!selected) {
                    setOpenDefaultAlert(true)
                  }
                  else {
                    nextStep()
                  }
                }} >Continuar</button> */}
              <Tarja />

            </div>
          </div>
        )
      case 4:
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <CheckoutIndicator />
            <input id={"ccnum"} type="text" hidden value={number.replaceAll(" ", "")} />
            <div className="container">
              <p>Agora precisamos que preencha os dados do <strong>seu cartão de crédito</strong></p>
              <Cards
                cvc={cvc}
                expiry={expiry}
                focused={focus}
                name={name}
                number={number}
              />
              <InputComponent className="smallPlaceHolder" name="name" label="Nome igual ao impresso no cartão de crédito" placeholder="Digite aqui o nome impresso no cartão" value={name} onChange={(e) => {
                if (/^[A-Za-zÀ-ÖØ-öø-ÿ]/.test(e.target.value.slice(-1)) || (e.target.value.slice(-1) === "" || e.target.value.slice(-1) === " ")) setName(e.target.value)
              }} onFocus={handleFocus} />
              <InputMaskComponent name="number" mask="9999 9999 9999 9999" label="Número do cartão" type="tel" placeholder="xxxx xxxx xxxx xxxx" value={number} onChange={(e) => {
                setNumber(e.target.value)
                handleFocus(e)
              }} />
              <div className="containerSmall">
                <InputMaskComponent id="expiry" name="expiry" label="Validade" mask="99/99" type="tel" placeholder="MM/AA" value={expiry} onChange={handleExpiry} />
                <InputMaskComponent name="cvc" label="CVV" icon={<button onClick={() => setOpenDialogCVV(true)}><HelpOutlineIcon fontSize="large" /></button>} mask={"999"} isCvv type="tel" placeholder="xxx" value={cvc} onChange={(e) => {
                  setCvc(e.target.value)
                  handleFocus(e)
                }} />
              </div>
              <div className="infoCardAccountPayment">
                <p>Aparecerá  <strong><u style={{ color: 'red' }}>PGW*STREETJOB</u></strong><br /> nas faturas do seu cartão de crédito.</p>
                <button onClick={() => {
                  nextStep()
                }} className="buttonContinueCard">
                  Continuar
                </button>
              </div>

              <button
                className="simulatorOtherValue"
                onClick={() => {
                  setCheckoutSteps(1)
                  setselected(null)
                  setProgressIndicator(progressIndicator - 1)
                }}
              >Quero simular um empréstimo menor</button>
              <img className="logoSSL" src={logoSSL} alt="Logo SSL" />
              <Tarja />
            </div>
          </div>
        )
      case 5:
        return (
          <div className="ContainerDontHaveCard">
            <OperationError />
            <div className="title">
              <p>Operação indisponível</p>
            </div>
            <div className="description">
              <p>Que pena! infelizmente não podemos dar continuidade no momento. Tente novamente assim que o seu
                cartão estiver com o <strong>saldo disponível para compras acima de {formatCurrency(preApproved as number)}.</strong>
              </p>
            </div>
            <div className="containerButtons">
              <button onClick={() => {
                setShowPreApproved(false)
                setShowParcels(false)
                setCheckoutSteps(1)
                sendToShow("limit")
              }} className="exitButton">Voltar</button>
            </div>

          </div>
        )
      case 6:
        return (
          <div style={{ width: '100vw', height: '100vh' }}>
            <iframe src={`${process.env.REACT_APP_ACANTO_CHALLENGE_URL}${challengeId}`} style={{ width: '100%', height: '100%' }} allow={`geolocation ${`${process.env.REACT_APP_ACANTO_CHALLENGE_URL}${challengeId}`}`} />
          </div>
        )
      default:
        break;
    }
  }

  const resetProposal = () => {
    sessionStorage.clear()
    /* @ts-ignore */
    location.reload(true) // eslint-disable-line
  }

  const renderTimer = (timer: number) => {
    const minute = Math.trunc(timer / 60)
    const seconds = timer % 60
    return `0${minute}:${seconds > 9 ? seconds : "0" + seconds}`
  }


  const finishProposal = async (data?: {
    failed3ds?: boolean
  }) => {
    await Axios.patch(`${process.env.REACT_APP_ONLINE_API}/proposals/finish`, data, {
      headers: {
        'Content-Type': 'application/json',
        'x-token': proposalToken,
      }
    }).then(() => {
      if (!data?.failed3ds) {
        resetProposal()
      }
    })
  }

  const sendJWT3DS = async (jwt: string) => {
    console.log('-----------', jwt)
    setLoading(true)
    await Axios.patch(`${process.env.REACT_APP_ONLINE_API}/proposals`, {
      challengeThreeDs: {
        jwt
      }
    }, {
      headers: {
        'Content-Type': 'application/json',
        'x-token': proposalToken,
      }
    }).then(({ data }) => {
      console.log(data)
      if (data.proposal_status === 'transfeera') {
        HandleSteps(3)
        setOpenDialogCardAproved(true)
      } else {
        setOpenDialogFailed3ds(true)
        console.log("CHALLENGE CANCELADO")
      }
    }).catch((e) => {
      const lastTry = "pagway"

      let integrations = e.response.data.message


      const casesErrors = {
        "pagway": errorsCasesCheckoutPagway,
        "acanto": errorsCasesCheckoutAcanto
      }

      const code = casesErrors[lastTry].filter((item) => item.code === integrations?.codigo)

      if (code.length > 0) {
        if (code.length === 1) {
          if (code[0].code === '010' || code[0].code === '017' || code[0].code === '867') {
            setOpenFirtInsufficientLimit(true)
            setLoading(false)
            return
          }
          if (codesToSendBlockedCard[lastTry].includes(code[0].code)) {
            setErrorReason('Seu cartão de crédito ainda está bloqueado para compras online 🔒. Entre em contato com o atendimento 📞 0800 no verso do seu cartão e solicite o DESBLOQUEIO 🔓')
            // setCanRetry(true)
          } else {
            setErrorReason(code[0].message)
            setAction(code[0].action)
          }
          setOpenDialogForLimit(true)
        } else {
          if (integrations?.message.includes('duplicidade')) {
            const correct = code.find((item) => item.message.includes('duplicidade'))
            setErrorReason(correct?.message as string)
            setAction(correct?.action as string)
            setOpenDialogForLimit(true)
            // const value = blackListValues
            // value.push(transformValue(valueProposal))
            // setBlackListValues(value)
          } else {
            const correct = code.find((item) => item.action === "finish")
            setErrorReason(correct?.message as string)
            setAction(correct?.action as string)
            setOpenDialogForLimit(true)
          }
        }
      } else {
        setOpenDialogCardError(true)
        setErrorReason(integrations?.descricao ?? "")
      }

    }).finally(() => {
      setLoading(false)
    })
  }

  return (
    <>

      <Dialog open={openDialogChallenge} className="DialogCardAlert">
        <p>VALIDAÇÃO DE SEGURANÇA NO SEU CARTÃO DE CRÉDITO</p>
        <p style={{ padding: '0px 10px', fontSize: '2.5rem' }}>Por motivos de segurança, fizemos uma pequena <strong>COMPRA no seu CARTÃO DE CRÉDITO</strong>, o valor foi <strong>entre R$ 0,01 e R$ 5,00</strong> em nome de <strong style={{ color: 'red', fontSize: '2.5rem' }}>ACANTO*Seguro</strong> <br /><br />Verifique o <strong>VALOR DA COMPRA</strong> para responder o desafio <strong>ACANTO*Seguro</strong></p>

        <button
          className="buttonChallenge"
          onClick={() => {
            setOpenDialogChallenge(false)
            setCheckoutSteps(6)
            setGetStatus(true)
          }}
        >Informar o <strong>VALOR DA COMPRA ACANTO*Seguro</strong></button>
        {/* <p style={{ padding: '0px 10px', fontSize: '2rem' }}>Obs: Permita que a Ayude tenha acesso à sua localização.</p> */}
      </Dialog>
      <Dialog open={openDialog} className="DialogCardAlert">
        <p>Atenção</p>
        <p>Em seu extrato ou fatura mensal do cartão de crédito aparecerá o nome:
          <br />
          <strong> AYUDE</strong></p>
        <button onClick={() => {
          sendCheckoutRequest()
        }}>Entendi</button>
      </Dialog>

      <Dialog open={openDialogNotAproved} className="DialogCardAlert">
        <p>NÃO APROVADO</p>
        <p>Cartão de Crédito não pertence ao CPF do cliente cadastrado!</p>
        <button onClick={() => {
          finishProposal()
        }}>Entendi</button>
      </Dialog>

      <Dialog open={openDialogNotAprovedByChallenge} className="DialogCardAlert">
        <p>NÃO APROVADO</p>
        <p>Devido a falha no desafio, não é possível prosseguir com a operação!</p>
        <button onClick={() => {
          finishProposal()
        }}>Entendi</button>
      </Dialog>

      <Dialog open={openDialogCVV} onClose={() => setOpenDialogCVV(false)}>
        <DialogContent className="DialogCVV">
          <img src={CVVImage} alt="CVVImage" />
          <p className="text">O <strong className="text">CVV</strong> é um <strong className="text">código de segurança de 3 dígitos</strong> presente no <strong className="text">verso do cartão</strong></p>
        </DialogContent>
      </Dialog>

      <Dialog open={openFormError} className="DialogRegister">
        <p>Por favor siga as instruções abaixo para prosseguir:</p>
        <ul>
          {errorList.map((item) => {
            return <li key={item.path}>{item.message}</li>
          })}
        </ul>
        <button onClick={() => setOpenFormError(false)}>Entendi</button>
      </Dialog>

      <Dialog open={openDialogCardError} className="dialogCardError">
        <p>TRANSAÇÃO NÃO AUTORIZADA</p>

        <p>{errorReason}</p>
        <button
          onClick={() => {
            resetProposal()
          }}>Entendi</button>
      </Dialog>

      <Dialog open={openDialogForLimit} className="dialogCardError">
        <>
          <p><u>{clientName}</u>,</p>

          {errorReason.split('.').map((item) => {
            return item !== '' && <p
              style={{
                padding: '0px 10px'
              }}
            >{item}.</p>
          })}

          <button
            style={{ height: 'auto', padding: '10px 0px' }}
            onClick={() => {
              if (canRetry) {
                setOpenDialogForLimit(false)
              } else {
                resetProposal()
              }
            }}>{canRetry ? <span>CONTINUAR<br />Eu já solicitei o desbloqueio 🔓
            </span> : "Entendi"}</button>
        </>
      </Dialog>

      <Dialog open={openDialogForCardBlock} className="errorCardForLimit">
        <>
          <p>CARTÃO DE CRÉDITO JÁ VERIFICADO ANTERIORMENTE!</p>

          <p>Caro cliente,</p>

          <p>Acreditamos que seu cartão já foi verificado anteriormente.</p>

          <p style={{ textAlign: 'left' }}> - Seu cartão não tinha saldo suficiente;</p>
          <p style={{ textAlign: 'left' }}> - Seu cartão não está liberado para compras online.</p>


          <p style={{ color: 'red' }}>Uma nova tentativa poderá bloquear seu cartão.</p>
          <p><u><strong>Sugerimos que tente novamente após 1 dia.</strong></u></p>


          <button
            style={{ padding: '10px' }}
            onClick={() => {
              resetProposal()
              setOpenDialogForCardBlock(false)
            }}>Entendi</button>
        </>
      </Dialog>

      <Dialog open={openDialogError}>
        <p><strong>Digite o saldo disponível do seu cartão</strong> de crédito para continuar</p>
        <button onClick={() => setOpenDialogError(false)}>Digitar</button>
      </Dialog>

      <Dialog open={openDialogCardAproved}>
        <div style={{ fontSize: '2rem', textAlign: 'center', padding: '0px 20px' }}>
          <p>Seu cartão de crédito foi aprovado!</p>
          <p>Em seu extrato ou fatura mensal do cartão de crédito aparecerá o nome:
            <br />
            <strong> <u style={{ color: 'red' }}>PGW*STREETJOB</u></strong></p>
        </div>
        <button onClick={() => {
          setOpenDialogCardAproved(false)
          setStep(7)
          setProgressIndicator(progressIndicator + 1)
        }}
          className="buttonConcluirEmprestimo"
        >Continuar</button>
      </Dialog>

      <Dialog open={openDefaultAlert}>
        {!valueInBlackList ?
          <p>O valor não pode ser igual ao anterior!</p>
          : checkoutSteps === 4 ?
            <p><strong>Selecione a quantidade de parcelas</strong> antes de continuar</p> :
            checkoutSteps === 1 && transformValue(valueProposal) < minValue && transformValue(valueProposal) > 0 ? <p style={{
              fontSize: '18px',
              padding: '0px 20px'
            }}>O valor mínimo para empréstimo é de <strong>R$ {minValue},00</strong></p> : <p className="dialogGeneric">{messageError} {transformValue(valueProposal) > Number(preApproved) && `${formatCurrency(preApproved as number)}`}</p>}
        <button onClick={() => setOpenDefaultAlert(false)}>{checkoutSteps === 4 ? "Selecionar" : "Digitar"}</button>
      </Dialog>

      <Dialog open={openDialogSameValue}>
        <DialogContent className="containerSameValue">
          <p style={{
            fontSize: '16px',
            textAlign: 'center'
          }}>Você já recebeu o valor de {moneyMask(valueProposal)} hoje.</p>
          <p style={{
            fontSize: '16px',
            textAlign: 'center'
          }}>Sugerimos que solicite um valor diferente para essa proposta.
          </p>
          <p style={{
            fontSize: '16px',
            textAlign: 'center'
          }}>Motivo: Por segurança não é permitido solicitar valores iguais no <br /> mesmo dia.</p>
          <button onClick={() => setOpenDialogSameValue(false)} className="buttonContinue">Entendi</button>
        </DialogContent>
      </Dialog>

      <Dialog open={redonlyAlert}>
        <p>Digite apenas múltiplos de R$ 10. Ex.: {formatCurrency(transformValue(valueProposal) - (transformValue(valueProposal) % 10))}</p>
        <button onClick={() => setOpenRedonlyAlert(false)}>Digitar</button>
      </Dialog>

      <Dialog open={openFirtInsufficientLimit} className="firstInsufficientLimit">
        <>
          <p><u>{clientName}</u>,</p>

          <p className="emphasis"><u>SALDO INDISPONÍVEL</u> NO SEU CARTÃO</p>

          <p>Passo 1 - Verifique se o saldo disponível em seu cartão é maior que <strong>{formatCurrency(proposalAmounts?.total as number)}</strong>
          </p>
          <p>Passo 2 - Entre em contato com a operadora do seu cartão e solicite a liberação imediata para compras online.</p>

          <p><strong><u>Somente após seguir os 2 passos acima</u></strong>, inicie uma nova proposta, caso contrário <strong><u>seu cartão poderá ser bloqueado.</u></strong></p>

          <button
            onClick={() => {
              resetProposal()
            }}>Entendi</button>
        </>
      </Dialog>

      <Dialog open={openSummary} onClose={() => {
        setOpenSummary(false)
        setselected(null)
      }} className="dialogAlert">
        <>
          <p className="titleAlert">CONFIRMAÇÃO DO SALDO DISPONÍVEL NO CARTÃO DE CRÉDITO</p>

          {/* <p style={{ color: 'red' }}>O valor do saldo a ser utilizado em <u style={{ color: 'red' }}>seu cartão de crédito</u> é <strong>{formatCurrency(selected?.total as number)}</strong></p> */}

          <p>Para continuarmos é importante que verifique se seu cartão de crédito está liberado para compras online.
          </p>

          <p>Nos confirme se o <strong>saldo disponível para compras em seu cartão de crédito</strong> é:
          </p>

          <div className="containerValuesMinMax">
            <button
              className="buttonValuesMinMax"
              onClick={() => {
                setOpenSummary(false)
                setselected(null)
                setOtherValue(true)
                sendToShow("otherValue")
              }}>
              <p style={{
                fontSize: '1.5rem',
                color: '#fff'
              }}>INFERIOR A:
                <br />
                {formatCurrency(selected?.total as number)}
              </p>
            </button>
            <p className="or">ou</p>
            <button
              className="buttonValuesMinMax"

              onClick={() => {
                // nextStep()
                amountUpdate()
                // setCheckoutSteps(checkoutSteps + 1)
                setOpenSummary(false)
              }}>
              <p style={{
                fontSize: '1.5rem',
                color: '#fff'
              }}>SUPERIOR A:
                <br />
                {formatCurrency(selected?.total as number)}
              </p>
            </button>
          </div>
        </>
      </Dialog>

      <Dialog open={openDialogTimer}>
        <div className="dialogTimer">
          <p>{renderTimer(timerCount)}</p>
          <p>Estamos aguardando você entrar em contato com a
            operadora de seu cartão de crédito e solicitar a autorização para compras online no valor
            de {formatCurrency(selected?.total as number ?? proposalAmounts?.total)}</p>
        </div>
      </Dialog>

      <Dialog open={openDialogAvoidBlocking} className="dialogAvoidBlocking">
        <>
          <p className="titleBlockingConfirm">{clientName}</p>


          <div className="cardAvoid">
            <p style={{ color: 'red', margin: '0px' }}><u>PARA EVITAR O BLOQUEIO <br />DO SEU CARTÃO DE CRÉDITO</u></p>
            <p>Apenas clique em <u>confirmo</u>, após verificar o seu
              <strong> SALDO DISPONÍVEL PARA COMPRAS</strong>
            </p>
          </div>

          <button className="buttonAvoidBlockingConfirm" onClick={() => setOpenDialogAvoidBlocking(false)}><u>CONFIRMO</u> QUE VERIFIQUEI, <br />
            MEU SALDO DISPONÍVEL<br /> É
            <u> MAIOR</u> QUE {formatCurrency(proposalAmounts?.total as number)}
          </button>

          <button className="buttonAvoidBlockingNotConfirm" onClick={() => {
            setCheckoutSteps(1)
            setselected(null)
            setProgressIndicator(progressIndicator - 1)
            setOpenDialogAvoidBlocking(false)
          }}>
            O meu saldo disponível <br />é menor que {formatCurrency(proposalAmounts?.total as number)} <br />
            <u>Clique aqui para simular outro valor para seu empréstimo</u>
          </button>

        </>

      </Dialog>

      <Dialog open={openDialogOfficeHour}>
        <DialogTitle>
          <div style={{
            display: 'flex',
            flexDirection: "row",
            alignItems: 'center',
            justifyContent: 'center',
            gap: '10px',
            fontSize: '2.4rem',
            flexWrap: 'wrap'
          }}>
            <img
              src={WarningIcon}
              style={{
                minWidth: '5rem',
                height: '4rem'
              }} />
            <strong>ATENÇÃO
            </strong>
          </div>
        </DialogTitle>
        <DialogContent>
          <p style={{ textAlign: 'center', marginBottom: '20px', fontSize: '2rem', textTransform: 'uppercase', fontWeight: 'bold' }}>
            Horário para crédito <br />em sua conta bancária!
          </p>
          <p style={{ textAlign: 'center', marginBottom: '20px', fontSize: '2rem' }}>
            <strong>As 09:00 horas do próximo dia útil</strong> é a previsão para depósito em sua conta bancária, devido ao horário de expediente bancário.
          </p>

        </DialogContent>
        <DialogActions
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: '1.5rem'
          }}
        >
          <button
            style={{
              width: 'auto',
              fontSize: '1.7rem',
              height: 'auto',
              backgroundColor: 'var(--green-button-color)',
              color: 'var(--blue-text-color)',
              padding: '1.5rem 2rem',
              border: 'none',
              borderRadius: '4px',
              fontWeight: 'bold'
            }}
            onClick={() => {
              setOpenDialogOfficeHour(false)
            }}
          >
            CONCORDO, QUERO CONTINUAR
          </button>
          <button
            style={{
              width: 'auto',
              fontSize: '1.7rem',
              height: 'auto',
              backgroundColor: '#d5d8de',
              color: 'black',
              padding: '1.5rem 2rem',
              border: 'none',
              borderRadius: '4px',
            }}
            onClick={() => {
              window.open('https://ayude.com.br', '_self', '')
            }
            }
          >
            NÃO CONTINUAR
          </button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDialogFailed3ds}>
        <DialogContent>
          <h1 style={{
            textAlign: "center"
          }}>A verificação do seu cartão de crédito falhou</h1>
          <h1 style={{
            textAlign: "center"
          }}>Digite os dados novamente</h1>
        </DialogContent>
        <DialogActions>
          <button style={{
            width: 'auto',
            fontSize: '1.7rem',
            height: 'auto',
            backgroundColor: 'var(--green-button-color)',
            color: 'var(--blue-text-color)',
            padding: '1.5rem 2rem',
            border: 'none',
            borderRadius: '4px',
            fontWeight: 'bold'
          }} onClick={() => {
            setOpenDialogFailed3ds(false)
            resetProposal()
          }}>Entendi</button>
        </DialogActions>
      </Dialog>

      {render()}
    </>
  )
}